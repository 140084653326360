import axios from "axios";
import qs from "qs";

const baseUrl = process.env.VUE_APP_WORKSHOPS_API_URL;

const instance = axios.create({
    baseURL: baseUrl,
    withCredentials: true, // Check cross-site Access-Control
    headers: {
        Accept: 'application/json'
    },
    paramsSerializer: params => {
        return qs.stringify(params, {arrayFormat: 'brackets'});
    }
});

instance.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        const code = parseInt(error.response && error.response.status)
        if (code === 401) {
            window.location.href = `${baseUrl}sso/login?redirect_to=${location.href}`
        }
        return Promise.reject(error);
    }
);

export default instance;
