import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    table: '',
    user: {},
    data: [],
    permissions: [],
    roles: [],
    editInDialog: true,
  },
  getters: {
    getUser: state => {
      return state.user;
    },
    getRoles: state => {
      return state.roles;
    },
  },
  mutations: {
    setTable(state, table) {
      state.table = table;
    },

    setUser(state, user) {
      state.user = user;
      state.user.name = user.first_name + " " + user.last_name;
    },

    setPermissions(state, permissions) {
      state.permissions = permissions;
    },

    setRoles(state, roles) {
      state.roles = roles;
    },

    setData(state, data) {
      state.data = data;
    },

    setEditInDialog(state, editInDialog) {
      state.editInDialog = editInDialog;
    }
  },
  actions: {
    setUser({commit}, user){
      commit("setUser", user);
    },

    setRoles({commit}, roles){
      commit("setRoles", roles);
    }
  },
  modules: {
  }
})
