<template>
  <v-container class="mb-10">
    <v-row class="justify-center">
      <v-col cols="12" sm="10" md="8" class="pa-0">
        <v-container>
          <v-row>
            <v-col class="pb-0">
              <h2 class="headline">Detalji radionice</h2>
            </v-col>
              <v-col cols="3" v-if="workshopRaw.isAuthCompleted">

                  <v-btn depressed
                          class="text-none"
                          color="primary"
                          height="44"
                          block
                          @click="issuesCertificates">
                      <v-icon
                              left
                              dark
                      >
                          mdi-download
                      </v-icon>
                      Preuzmite certifikat

                  </v-btn>
              </v-col>
          </v-row>
          <v-row v-if="$checkRole('applications_admin')">
            <v-col>
              <v-breadcrumbs class="pa-0" :items="breadcrumbs" large></v-breadcrumbs>
            </v-col>
          </v-row>
          <v-row>
            <v-col><v-divider></v-divider></v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="12" sm="10" md="8" class="pa-0">
        <v-container>
          <v-row v-if="register_until">
            <v-col v-if="expired_registration">
              <v-alert type="error">Isteklo vrijeme za prijavu na radionicu!</v-alert>
            </v-col>
          </v-row>

          <!--  Workshop info -->
          <v-row>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="6" class="hidden-md-and-up">
                  <a href="https://youtu.be/KDWNqYKNcj0" target="_blank">
                    <v-img
                      v-if="workshopId === '53'"
                      src="../../assets/img/youtube-link.jpeg"
                      class="mb-3"
                    ></v-img>
                  </a>
                  <a href="https://youtu.be/mX4-ahcP0bo" target="_blank">
                    <v-img
                      v-if="workshopId === '54'"
                      src="../../assets/img/youtube-pos.jpeg"
                      class="mb-3"
                    ></v-img>
                  </a>
                  <a href="https://youtu.be/tFNmgLu2jl0" target="_blank">
                    <v-img
                      v-if="workshopId === '55' || workshopId === '59'"
                      src="../../assets/img/youtube-link-sb.jpeg"
                      class="mb-3"
                    ></v-img>
                  </a>
                  <a href="https://youtu.be/JYl9-zVtRM0" target="_blank">
                    <v-img
                      v-if="workshopId === '56'"
                      src="../../assets/img/youtube-zzh.jpeg"
                      class="mb-3"
                    ></v-img>
                  </a>
                  <a href="https://youtu.be/SHB68pR5ZHk" target="_blank">
                    <v-img
                      v-if="workshopId === '57'"
                      src="../../assets/img/youtube-hnz.jpeg"
                      class="mb-3"
                    ></v-img>
                  </a>
                  <v-img
                    v-if="imageId"
                    :src="`https://api.eduid.sum.ba/files/${imageId}`"
                  ></v-img>
                </v-col>
                <v-col v-if="!expired_registration">
                  <div v-if="!isAuthApplied">
                    <v-btn
                      depressed
                      color="primary"
                      class="text-none mr-3 mb-0 mb-md-2"
                      @click="openApply(1)"
                      width="250px"
                    >
                      Prijavite se na radionicu
                    </v-btn>
                    <v-btn
                      v-if="$checkRole('applications_admin')"
                      depressed
                      color="primary"
                      class="text-none mt-2 mt-sm-0"
                      @click="openApply(2)"
                      width="250px"
                    >
                      Prijavite se kao predavač
                    </v-btn>
                  </div>
                  <div v-else>
                    <v-alert type="info" color="blue darken-1">
                      Prijavljeni ste na radionicu
                    </v-alert>
                    <v-btn
                      depressed
                      color="primary"
                      class="text-none"
                      @click="openApply(1, false)"
                      width="250px"
                    >
                      Odjavite se s radionice
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row v-for="(value, propertyName, index) in workshop" :key="index">
                <v-col>
                  <span class="font-weight-light">{{propertyName}}: </span>
                  <div class="pl-3" v-if="propertyName === 'Opis'">
                    <span v-html="value"></span>
                  </div>

                  <a v-else-if="propertyName === 'Link radionice'"
                    :href="value"
                    class="text-decoration-none"
                    target="_blank"
                  >{{value ? value : '-'}}</a>

                  <span v-else class="font-weight-bold">
                    {{value ? value : '-'}}
                  </span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6" class="hidden-sm-and-down">
              <a href="https://youtu.be/KDWNqYKNcj0" target="_blank">
                <v-img
                  v-if="workshopId === '53'"
                  src="../../assets/img/youtube-link.jpeg"
                  class="mb-3"
                ></v-img>
              </a>
              <a href="https://youtu.be/mX4-ahcP0bo" target="_blank">
                <v-img
                  v-if="workshopId === '54'"
                  src="../../assets/img/youtube-pos.jpeg"
                  class="mb-3"
                ></v-img>
              </a>
              <a href="https://youtu.be/tFNmgLu2jl0" target="_blank">
                <v-img
                  v-if="workshopId === '55' || workshopId === '59'"
                  src="../../assets/img/youtube-link-sb.jpeg"
                  class="mb-3"
                ></v-img>
              </a>
              <a href="https://youtu.be/JYl9-zVtRM0" target="_blank">
                <v-img
                  v-if="workshopId === '56'"
                  src="../../assets/img/youtube-zzh.jpeg"
                  class="mb-3"
                ></v-img>
              </a>
              <a href="https://youtu.be/SHB68pR5ZHk" target="_blank">
                <v-img
                  v-if="workshopId === '57'"
                  src="../../assets/img/youtube-hnz.jpeg"
                  class="mb-3"
                ></v-img>
              </a>
              <v-img
                v-if="imageId"
                :src="`https://api.eduid.sum.ba/files/${imageId}`"
              ></v-img>
            </v-col>
            <v-col class="pt-0">
              <span class="font-weight-light">Oznake: </span>
              <span class="font-weight-bold" v-for="tag in tags" :key="tag.id">
                <v-chip class="mr-2 mb-2">{{tag.name}}</v-chip>
              </span>
            </v-col>

          </v-row>
            <v-card class="pt-5 pl-5 pr-5" outlined v-if="$checkRole('applications_admin')">
              <v-container class="pa-8">
                <v-row>
                  <v-col cols="12" >

                    <vue-dropzone
                            :id="workshopId"
                              :options="dzSingle"
                              ref="dropzone"
                              @vdropzone-complete="(file) => onFileUpload(file)"
                              style="margin-top: 5px;"
                      >
                      </vue-dropzone>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                            depressed
                            class="text-none"
                            color="primary"
                            height="44"
                            block
                            @click="updateWorkshop"
                    >
                      Dodaj predložak
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>

        </v-container>
      </v-col>

      <!-- Participants -->
      <v-col cols="12" v-if="$checkRole('applications_admin')">
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
              outlined
              placeholder="Pretraži..."
              hide-details
              dense
              v-model="search"
              append-icon="mdi-magnify"
              height="44"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
                :headers="headers"
                :items="participants"
                :options.sync="options"
                :server-items-length="totalItems"
                loading-text="Traženje..."
                :footer-props="{itemsPerPageText: 'Redaka po stranici:'}"
                :header-props="{sortByText: 'Poredaj po'}"
            >
              <template v-slot:[`item.id`]="{item}">
                <v-checkbox
                  v-model="item.pivot.confirmed"
                  @click="workshopConfirmAttendance(item)"
                >
                </v-checkbox>
              </template>
              <template v-slot:[`item.pivot.status`]="{item}">
                <v-edit-dialog

                  large
                  persistent
                  save-text="Potvrdi"
                  cancel-text="Odustani"
                  @save="updateStatus(item)"
                  @cancel="closeDialog"
                  @open="openDialog(item)"
                >
                  <div>{{ item.pivot.status ? 'Položio' : 'Nije položio' }}</div>
                  <template v-slot:input>
                    <div class="mt-4 title">
                      Uredi status
                    </div>
                    <v-select
                      label="Status"
                      :items="statuses"
                      v-model="participantStatus"
                      item-text="status"
                      item-value="id"
                    />
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:[`item.actions`]="{item}">
                <v-btn
                  depressed
                  color="error"
                  @click="openDetach(item.id)"
                >
                  <v-icon>mdi-account-arrow-right-outline</v-icon>
                </v-btn>
              </template>
              <template v-slot:[`item.pivot.type`]="{item}">
                <span v-if="item.pivot.type === 1">Sudionik</span>
                <span v-else-if="item.pivot.type === 2">Predavač</span>
              </template>
              <template v-slot:[`item.avatar`]="{item}">
                <v-avatar
                    size="50px"
                    class="avatar-img"
                >
                  <img
                      alt="Avatar"
                      class="image-in-avatar"
                      :src="'https://api.eduid.sum.ba/users/'+item.uid+'/avatar'"/>
                </v-avatar>
              </template>
              <template v-slot:no-data>
                <span>
                    Za tražene pojmove nema rezultata pretrage.
                </span>
              </template>
              <template v-slot:[`footer.page-text`]="items"> Prikazano {{ items.pageStart }} do {{ items.pageStop }} od
                ukupno
                {{ items.itemsLength }} rezultata
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- apply to workshop dialog -->
    <v-dialog
      v-model="applyDialog"
      width="500"
    >
      <v-card>
        <v-card-title
            primary-title>
          Jeste li sigurni?
        </v-card-title>

        <v-card-text style="padding-top: 20px;">
            {{applyDialogTitle}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="text-none" @click="applyDialog = false" depressed large outlined>Odustani</v-btn>
          <v-btn class="text-none" @click="handleAction" color="primary" depressed large>Potvrdi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- delete dialog -->
    <v-dialog
    v-model="detachDialog"
    width="500">
      <v-card>
        <v-card-title
            primary-title>
          Jeste li sigurni?
        </v-card-title>

        <v-card-text style="padding-top: 20px;">
          Jeste li sigurni da se želite odjaviti sudionika radionice?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="text-none" @click="detachDialog = false" depressed large outlined>Odustani</v-btn>
          <v-btn class="text-none" @click="detachParticipant" color="error" depressed large>Odjavi sudionika</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<style lang="scss">
.avatar-img {
  border: 1px solid rgba(8,71,118,0.16);
  border-radius: 100px;
}
.image-in-avatar {
  object-position: 15% 10%;
  object-fit: cover;
}
</style>

<script>
import Api from "../../services/Api"
import {serverBus} from "../../main"
import VueDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {

  components: {
    VueDropzone
  },
  created() {
    this.workshopId = this.$route.params.radionica_id;

    this.getWorkshop();
    this.getParticipants();
  },

  computed: {
    breadcrumbs() {
      return [
        {
          text: 'Radionice',
          disabled: false,
          exact: true,
          to: '/radionice',
        },
        {
          text: this.workshopTitle,
          disabled: true
        },
      ]
    }
  },

  watch: {
    options: {
      handler() {
        this.getParticipants()
      },
      deep: true
    },
    search: {
      handler() {
        this.options.page = 1
        this.getParticipants()
      },
      deep: true
    }
  },

  methods: {
    openApply(type, isApply = true) {
      this.applyDialog = true;
      this.registerType = type;
      this.isApply = isApply

      if(!this.isApply) {
        this.applyDialogTitle = 'Jeste li sigurni da se želite odjaviti s radionice?'
      }
    },

    openDialog (item) {
      this.participantStatus = item.pivot.status
    },

    closeDialog () {
      this.participantStatus = 0
    },

    openDetach(id) {
      this.detachDialog = true;
      this.participantId = id;
    },

    handleAction() {
      if(this.isApply) {
        this.applyToWorkshop();
      } else {
        this.unregister();
      }
    },

    applyToWorkshop() {
      Api.post(`workshops/${this.workshopId}/apply`, {
        type: this.registerType,
      }).then(() => {
        serverBus.$emit('show-snackbar', true, 'Uspješno ste se prijavili.');
        this.applyDialog = false;
        this.getWorkshop()
        this.getParticipants();
      }).catch(() => {
        serverBus.$emit('show-snackbar', false);
      })
    },

    unregister() {
      Api.post(`workshops/${this.workshopId}/unregister`)
        .then(() => {
          serverBus.$emit('show-snackbar', true, 'Uspješno ste se odjavili.');
          this.applyDialog = false;
          this.getWorkshop()
          this.getParticipants();
        }).catch(() => {
          serverBus.$emit('show-snackbar', false);
        })
    },

    getParticipants() {
      let params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        sortDesc: this.options.sortDesc,
        sortBy: this.options.sortBy,
        filter: {
          first_name: '~=' + this.search
        }
      }

      Api.get(`workshops/${this.workshopId}/participants`, {
        params: params
      }).then(response => {
          this.participants = response.data.data;
          this.totalItems = response.data.total;
        })
    },

    getWorkshop() {
      Api.get(`workshops/${this.workshopId}`, {params: {with: ['tags','image']}})
        .then(response => {
          this.optimizeData(response.data);
          this.workshopRaw = response.data;
          this.form = response.data
          const {image, expired_registration, tags, title, isAuthApplied} = response.data

          this.imageId = image?.uuid
          this.expired_registration = expired_registration;
          this.isAuthApplied = isAuthApplied
          this.tags = tags;
          this.workshopTitle = title;
        })
        .catch(error => {
          console.log(error);
        })
    },

    workshopConfirmAttendance(user){
      Api.post(`workshops/${this.workshopId}/participants`, {
        data: {
          [user.id]: {
            confirmed: user.pivot.confirmed
          }
        },
        method: "syncWithoutDetaching"
      }).then(() => {
        serverBus.$emit('show-snackbar', true);
        this.getParticipants()
      }).catch(() => {
        serverBus.$emit('show-snackbar', false);
      })
    },

    updateStatus(item){
      Api.put(`workshop_applications/${item.pivot.id}`, {
        status: this.participantStatus
      }).then(() => {
        serverBus.$emit('show-snackbar', true);
        this.getParticipants()
      }).catch(() => {
        serverBus.$emit('show-snackbar', false);
      })
    },

    detachParticipant() {
      Api.post(`workshops/${this.workshopId}/participants`, {
        data: this.participantId,
        method: 'detach'
      }).then(() => {
        serverBus.$emit('show-snackbar', true);
        this.detachDialog = false;
        this.getWorkshop()
        this.getParticipants();
      }).catch(() => {
        serverBus.$emit('show-snackbar', false);
      })
    },

    optimizeData(data){
      this.register_until = data.register_until;

      this.workshop = {
        'Naziv': data.title,
        'Podnaslov': data.subtitle,
        'Kontakt mail': data.contact_email,
        'Opis': data.description,
        'Lokacija': data.location,
        'Link radionice': data.meet_url,
        'Datum početka': this.$parseDate(data.date_from),
        'Datum završetka': this.$parseDate(data.date_to),
        'Vrijeme početka': this.$parseTime(data.time_start),
        'Vrijeme završetka': this.$parseTime(data.time_end),
        'Prijava do': this.$parseDate(data.register_until),
      }
    },
    onFileUpload(file) {
      if (file.accepted) {
        const response = file.xhr.response;
        this.file = JSON.parse(response);
        this.form.certificate_template_id = this.file.id
console.log(this.file)
        serverBus.$emit('show-snackbar', true,);
      } else {
        serverBus.$emit('show-snackbar', false);
      }
    },
    updateWorkshop() {
      Api.put(`workshops/${this.workshopId}`, this.form)
              .then((response) => {
                console.log(response.data)
                serverBus.$emit('show-snackbar', true);
                this.form = {};
                this.file = {};
              })
              .catch(() => {
                this.validateForm();
                serverBus.$emit('show-snackbar', false);
              })
    },
    issuesCertificates(){
      if (this.workshop.issued_certificate) {
        this.showCertificate(this.workshop.issued_certificate.certificate_file.uuid);
      }
      Api.get(`workshops/${this.workshopId}/issueCertificate`
      ).then((response) => {
        let uuid=response.data.certificate_file.uuid;
        serverBus.$emit('show-snackbar', true);
        this.showCertificate(uuid);
      }).catch(() => {
        serverBus.$emit('show-snackbar', false);
      })
    },

    showCertificate(uuid){
      window.open(process.env.VUE_APP_WORKSHOPS_API_URL + 'files/' + uuid);
    },

    addTemplate() {
      const data = {
        [this.form.file_id]: {
          label: this.form.label
        }
      }

      Api.post(`files/`, {
        data: data,
      }).then((response) => {
        this.form = {};
        this.files = response.data;
        this.$refs.dropzone[0].destroy();
        serverBus.$emit('show-snackbar', true);
      }).catch(() => {
        serverBus.$emit('show-snackbar', false);
      })
    },
  },

  data: () => ({
    workshop: {},
    workshopRaw: {},
    participants: [],
    statuses: [
      {
        id: 2,
        status: 'Položio',
      },{
        id: 1,
        status: 'Polagao'
      },
      {
        id: 0,
        status: 'Nije položio'
      },
    ],
    dialog:false,
    file:null,
    applyDialog: false,
    register_until: null,
    expired_registration: null,
    registerType: null,
    workshopId: null,
    uuid:null,
    workshopTitle: null,
    imageId: null,
    form:{},
    dzSingle: {
      url: process.env.VUE_APP_WORKSHOPS_API_URL + 'files',
      method: 'POST',
      maxFilesize: 20,
      maxFiles: 1,
      dictDefaultMessage: 'Priložite predložak...',
      withCredentials: true,
      addRemoveLinks: true
    },
    isApply: true,
    detachDialog: false,
    certificateId: null,
    participantStatus: 0,
    isAuthApplied: false,
    search: '',
    files: [],
    options: {},
    totalItems: 0,
    applyDialogTitle: 'Jeste li sigurni da se želite prijaviti na radionicu?',
    tags: [],
    headers: [
      {text: 'Slika', value: 'avatar'},
      {text: 'Ime', value: 'first_name'},
      {text: 'Prezime', value: 'last_name'},
      {text: 'Email', value: 'email'},
      {text: 'Email(privatni)', value: 'email_private'},
      {text: 'Tip', value: 'pivot.type'},
      {text: "Prisutnost", value: 'id'},
      {text: "Status", value: 'pivot.status'},
      {text: "Akcije", value: 'actions'}
    ],
  }),
};
</script>
