<template>
  <v-app>
    <admin-layout v-if="isAdmin"></admin-layout>
      <div v-else>
          <v-fade-transition mode="out-in">
            <router-view></router-view>
          </v-fade-transition>
      </div>

  </v-app>
</template>

<script>
import AdminLayout from "./layouts/AdminLayout";

export default {
  components: {
    AdminLayout
  },

  computed: {
    isAdmin () {
      return this.$route.path.match('radionice');
    }
  },

  data: function () {
    return {
      loggedIn: false,
    }
  }
}
</script>
