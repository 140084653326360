<template>
  <div>
    <v-app-bar app color="primary" dark fixed
              style="top: 0 !important;">
      <v-toolbar-title class="ml-0 pl-4 hidden-sm-and-down" style="width: 230px">
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu
          offset-y
          bottom
          origin="center center"
          transition="scale-transition"
          content-class="my-menu"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn text
                class="white--text text-capitalize"
                v-bind="attrs"
                v-on="on">
            <v-icon :small="false" :left="$vuetify.breakpoint.mdAndUp">mdi-account-circle</v-icon>
            {{ $getUser().first_name}}
            <v-icon x-small right class="hidden-md-and-down">mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list style="background-color: rgba(8,71,118,1)">
          <v-list-item @click="performLogout">
            <v-icon color="white" small left>mdi-logout-variant</v-icon>
            <v-list-item-title class="white--text">Odjava</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-container
            class="fill-height"
            :class="{'pa-0':  $vuetify.breakpoint.mdAndDown}">
        <v-fade-transition mode="out-in">
          <router-view :key="$route.path"></router-view>
        </v-fade-transition>
      </v-container>
    </v-main>

    <Snackbar />
  </div>
</template>

<script>
import Snackbar from "../components/custom/Snackbar";
import {serverBus} from '../main'
import Api from "../services/Api"

export default {
  components: {
    Snackbar
  },

  created() {
    this.getLoggedInUser();

    let self = this;

    serverBus.$on('logged-in-out', (loggedIn) => {
      self.loggedIn = loggedIn;

      if (!self.loggedIn) {
        this.$store.commit('setUser', {});

        let apiUrl = process.env.VUE_APP_WORKSHOPS_API_URL;
        let host = `${window.location.protocol}//${location.host}`

        window.location.href = `${apiUrl}sso/logout?redirect_to=${host}`
      }
    });
  },

  data: () => ({
    //
  }),

  methods: {
    getLoggedInUser() {
      Api.get('sso/user').then(response => {
        this.$store.dispatch('setUser', response.data.data);
        this.$store.dispatch('setRoles', response.data.data.roles);
      });
    },

    performLogout: function () {
      serverBus.$emit('logged-in-out', false)
    },
  }
};
</script>
