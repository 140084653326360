import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../components/login/LoginView.vue'

import WorkshopIndexView from "../components/workshops/WorkshopIndexView.vue"
import SingleWorkshop from "../components/workshops/SingleWorkshop.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LoginView',
    component: LoginView
  },
  {
      path: '/radionice',
      component: WorkshopIndexView,
      name: 'WorkshopTableView',
  },
  {
      path: '/radionice/:radionica_id',
      component: SingleWorkshop,
      name: 'SingleWorkshop',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
