import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/es5/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#004379',
                secondary:  colors.indigo,
                accent: '#E4002B',
                error: '#FF5252',
                info: '#21ee23',
                success: '#4CAF50',
                warning: '#FFC107',
                darken1: colors.purple.darken2,
                tertiary: colors.pink.base
            },
        },
    },
});
