<template>
      <v-container v-if="$checkRole('applications_admin')">
        <v-row class="justify-center">
          <v-col cols="12" class="pa-0">
            <v-container>
              <v-row>
                <v-col class="pb-0">
                  <h2 class="headline">Radionice</h2>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <!-- radionice u tijeku -->
        <v-row class="mt-8">
          <v-col cols="12" sm="4">
            <v-text-field
              outlined
              placeholder="Pretraži..."
              hide-details
              dense
              v-model="search"
              append-icon="mdi-magnify"
              height="44"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col
            v-if="$checkRole('applications_admin')"
            cols="12"
            sm="3"
          >
            <v-btn
              depressed
              block
              color="primary"
              class="text-none"
              @click="openAdd"
            >
              <v-icon>mdi-plus</v-icon> Dodaj
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
                :headers="headers"
                :items="workshops"
                @click:row="openDetails"
                :options.sync="options"
                :server-items-length="totalItems"
                style="cursor:pointer;"
                loading-text="Traženje..."
                :footer-props="{itemsPerPageText: 'Redaka po stranici:'}"
                :header-props="{sortByText: 'Poredaj po'}"
            >
              <template v-slot:[`item.is_finished`]="{item}">
                <span class="error--text font-weight-medium" v-if="item.is_finished">Završeno</span>
                <span class="info--text" v-else-if="item.expired_registration">Prijave završene</span>
                <span class="success--text" v-else>Prijave u tijeku</span>
              </template>
              <template v-slot:[`item.date_from`]="{item}">
                <span>{{ $parseDate(item.date_from) }}</span>
              </template>
              <template v-slot:[`item.time_start`]="{item}">
                <span>{{ $parseTime(item.time_start) }}</span>
              </template>
              <template v-slot:[`item.actions`]="{item}">
                <v-btn
                    class="action-button elevation-0 mr-2"
                    color="primary"
                    @click.stop="openEdit(item)"
                >
                  <v-icon
                      color="white"
                      size="16"
                  >mdi-pencil
                  </v-icon>
                </v-btn>
                <v-btn
                    class="action-button red darken-2 elevation-0"
                    @click.stop="openDelete(item.id)"
                >
                  <v-icon
                      color="white"
                      size="16"
                  >mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:no-data>
                <span>
                    Za tražene pojmove nema rezultata pretrage.
                </span>
              </template>
              <template v-slot:[`footer.page-text`]="items"> Prikazano {{ items.pageStart }} do {{ items.pageStop }} od
                ukupno
                {{ items.itemsLength }} rezultata
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <!-- add/edit dialog -->
        <v-dialog
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
          v-model="dialog"
          @click:outside="closeDialog"
        >
          <v-card>
            <v-toolbar color="primary" dark class="elevation-0">
                <v-toolbar-title>{{dialogTitle}}</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn @click.native="closeDialog" dark icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-container class="pt-8 px-6">
              <ValidationProvider
                :name="item.name"
                :rules="item.rules"
                mode="eager"
                ref="provider"
                :key="item.name"
                v-for="item in items"
              >
                <div slot-scope="{ errors }">
                  <v-row>
                    <v-col xs="12" md="3" style="height: 65px" class="d-flex justify-end">
                      <div class="d-flex align-center justify-end">
                        <p class="subtitle-3 font-weight-bold text--primary"
                          :style="{color: errors.length > 0 ? 'rgb(236 29 36) !important' : '', opacity: 0.8, marginTop: '5px',
                          marginRight: '15px'}">{{ item.title }}:</p>
                      </div>
                    </v-col>

                    <v-col xs="12" md="9">
                      <!-- Textfield -->
                      <v-text-field
                          dense
                          :name="item.name"
                          outlined
                          :placeholder="item.rules && item.rules.includes('required') ? 'Obavezno..' : ''"
                          v-if="item.tag === 'input'"
                          v-model="form[item.name]"
                      ></v-text-field>

                      <!-- Textarea -->
                      <v-textarea
                          dense
                          :name="item.name"
                          v-if="item.tag === 'textarea'"
                          v-model="form[item.name]"
                      ></v-textarea>

                      <!-- Date picker -->
                      <v-menu
                          v-model="item.model"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                          v-if="item.tag === 'date'"
                      >
                          <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  outlined
                                  dense
                                  v-model="form[item.name]"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                              ></v-text-field>
                          </template>
                          <v-date-picker v-model="form[item.name]" scrollable>

                          </v-date-picker>
                      </v-menu>

                      <!-- Timepicker -->
                      <v-menu
                          v-model="item.model"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                          v-if="item.tag === 'time'"
                      >
                          <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  outlined
                                  dense
                                  v-model="form[item.name]"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                              ></v-text-field>
                          </template>
                          <v-time-picker
                              :landscape="false"
                              :name="item.name"
                              format="24hr"
                              use-seconds
                              v-model="form[item.name]">
                          </v-time-picker>
                      </v-menu>

                      <!-- HTML editor -->

                          <div style="height: 320px"
                              v-if="item.tag === 'html'">
                              <vue-editor
                                :name="item.name"
                                style="height: 200px; margin-top: 5px;"
                                v-model="form[item.name]">
                              </vue-editor>
                          </div>

                      <!-- file upload -->
                      <div v-if="item.tag === 'file'" class="d-flex justify-space-between">
                          <vue-dropzone
                                  :id="'dropZone_' + item.name"
                                  :name="item.name"
                                  :options="dzSingle"
                                  ref="dropzone"
                                  @vdropzone-complete="(file) => onFileUpload(file, item)"
                                  @vdropzone-removed-file="(file, error, xhr) => onFileRemove(file, item, xhr)"
                                  style="margin-right: 5px; width: 100%"
                                  v-if="dropzones">
                          </vue-dropzone>
                          <div v-if="form.image && dialogTitle === 'Uredi radionicu'">
                            <img style="max-height: 200px" :src="`https://api.eduid.sum.ba/files/${form.image.uuid}`">
                          </div>
                      </div>

                      <!-- Multiselect -->
                      <v-combobox
                        dense
                        outlined
                        :items="item.source"
                        :name="item.name"
                        item-text="name"
                        item-value="id"
                        :placeholder="item.rules && item.rules.includes('required') ? 'Obavezno..' : ''"
                        :search-input.sync="selectSearch"
                        chips
                        multiple
                        v-if="item.tag === 'multiselect'"
                        v-model="form[item.name]"
                        @keydown.enter="addTag($event)"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              Nema rezultata za "<strong>{{ selectSearch }}</strong>". Pritisnite <kbd>enter</kbd> da kreirate novu oznaku.
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                    </v-col>
                  </v-row>
                </div>
              </ValidationProvider>
              <v-row>
                <v-col>
                  <v-btn
                    color="primary"
                    depressed
                    class="text-none mr-3"
                    large
                    width="250"
                    @click="handleAction"
                  >
                    Potvrdi
                  </v-btn>
                  <v-btn
                    outlined
                    depressed
                    class="text-none"
                    large
                    width="250"
                    @click="closeDialog"
                  >
                    Odustani
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>

        <!-- delete dialog -->
        <v-dialog
        v-model="deleteDialog"
        width="500">
          <v-card>
            <v-card-title
                primary-title>
              Jeste li sigurni?
            </v-card-title>

            <v-card-text style="padding-top: 20px;">
              {{ deleteText }}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="text-none" @click="deleteDialog = false" depressed large outlined>Odustani</v-btn>
              <v-btn class="text-none" @click="deleteWorkshop" color="error" depressed large>Izbriši</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
</template>

<script>
import Api from "../../services/Api"
import {serverBus} from "../../main"
import VueDropzone from 'vue2-dropzone'
import {VueEditor} from 'vue2-editor'

import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import {extend, setInteractionMode, ValidationProvider} from 'vee-validate';
import {digits, email, max, min, required} from 'vee-validate/dist/rules';

export default {
  components: {
    VueDropzone,
    ValidationProvider,
    VueEditor
  },

  created() {
    setInteractionMode('eager');

    extend('min', min);
    extend('max', max);
    extend('digits', digits);
    extend('email', email);
    extend('required', {...required, message: 'Ovo polje je obavezno.'});

    this.getWorkshops();
    this.getTags();
  },

  computed: {
    headers() {
      let headers = [
        {text: 'Naziv', value: 'title'},
        {text: 'Podnaslov', value: 'subtitle'},
        {text: 'Lokacija', value: 'location'},
        {text: 'Datum početka', value: 'date_from'},
        {text: 'Vrijeme početka', value: 'time_start'},
        {text: 'Broj sudionika', value: 'participants_count'},
        {text: 'Status', value: 'is_finished'},
        {text: "Akcije", value: 'actions'}
      ]

      if(this.$checkRole('applications_admin') === false){
        headers.pop()
      }

      return headers
    }
  },

  watch: {
    options: {
      handler() {
        this.getWorkshops()
      },
      deep: true
    },
    search: {
      handler() {
        this.options.page = 1
        this.getWorkshops()
      },
      deep: true
    }
  },

  methods: {
    openDetails(item) {
      this.$router.push(`/radionice/${item.id}`);
    },

    closeDialog() {
      this.dialog = false;
      this.form = {};
      for (let i = 0; i < this.$refs.provider.length; i++) {
        this.$refs.provider[i].reset();
      }

      this.$refs.dropzone[0].removeFile(this.file);
    },

    openAdd(){
      this.dialogTitle = 'Dodaj radionicu'
      this.dialog = true;
      this.workshopId = null;
    },

    openEdit(item) {
      this.dialogTitle = 'Uredi radionicu';

      this.form = {
        title: item.title,
        subtitle: item.subtitle,
        contact_email: item.contact_email,
        image_id: item.image_id,
        description: item.description,
        tags: item.tags,
        location: item.location,
        meet_url: item.meet_url,
        date_from: item.date_from,
        date_to: item.date_to,
        time_start: item.time_start,
        time_end: item.time_end,
        register_until_date: item.register_until ? item.register_until.split(" ")[0] : null,
        register_until_time: item.register_until ? item.register_until.split(" ")[1] : null
      }

      this.workshopId = item.id;
      this.dialog = true;
    },

    openDelete(workshopId) {
      this.workshopId = workshopId;
      this.deleteDialog = true;
    },

    validateForm() {
      for (let i = 0; i < this.$refs.provider.length; i++) {
        this.$refs.provider[i].validate();
      }
    },

    onFileUpload(file) {
      if (file.accepted) {
        const response = file.xhr.response;
        this.file = JSON.parse(response);

        serverBus.$emit('show-snackbar', true,);
      } else {
        serverBus.$emit('show-snackbar', false);
      }
    },

    onFileRemove() {
      if(this.file.id) {
        Api.delete(`files/${this.file.id}`);
      }
    },

    getWorkshops() {
      let params = {
        with: ['tags', 'image'],
        withCount: 'participants',
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        sortDesc: this.options.sortDesc,
        sortBy: this.options.sortBy,
        filter: {
          title: '~=' + this.search
        }
      }

      Api.get('workshops', {
        params: params
      }).then(response => {
          this.workshops = response.data.data;
          this.totalItems = response.data.total;
        })
        .catch(error => {
          console.log(error);
        })
    },

    getTags() {
      Api.get('tags', {
        params: {
          perPage: -1
        }
      }).then(response => {
          this.items.forEach(el => {
            if(el.name === 'tags'){
              el.source = response.data.data;
            }
          })
        })
        .catch((error) => {
          console.log(error);
        })
    },

    addTag(e) {
      Api.post('tags', {
        name: e.target.value
      }).then(() => {
          serverBus.$emit('show-snackbar', true);
          this.getTags();
        }).catch(() => {
          serverBus.$emit('show-snackbar', false);
        });
    },

    handleAction(){
      if(this.form.register_until_date && this.form.register_until_time){
        this.form['register_until'] = `${this.form['register_until_date']} ${this.form['register_until_time']}`;
      }

      this.form['image_id'] = this.file.id;

      let selectedTags = [];

      if(this.form.tags) {
        selectedTags = this.form.tags.map(el => el.id).filter(el => el !== undefined);
        console.log(selectedTags)
      }

      this.form.relations = {
        tags: {
          data: selectedTags,
          method: "sync"
        }
      }

      if(this.workshopId) {
        this.updateWorkshop();
      } else {
        this.createWorkshop();
      }
    },

    createWorkshop() {
      Api.post('workshops', this.form)
        .then((response) => {
          serverBus.$emit('show-snackbar', true);
          this.dialog = false;
          this.form = {};
          this.file = {};
          this.$router.push(`/radionice/${response.data.id}`);
        })
        .catch(() => {
          this.validateForm();
          serverBus.$emit('show-snackbar', false);
        })
    },

    updateWorkshop() {
      Api.put(`workshops/${this.workshopId}`, this.form)
        .then((response) => {
          serverBus.$emit('show-snackbar', true);
          this.dialog = false;
          this.form = {};
          this.file = {};
          this.$router.push(`/radionice/${response.data.id}`);
        })
        .catch(() => {
          this.validateForm();
          serverBus.$emit('show-snackbar', false);
        })
    },

    deleteWorkshop() {
      Api.delete(`workshops/${this.workshopId}`)
        .then(() => {
          serverBus.$emit('show-snackbar', true);
          this.deleteDialog = false;
          this.getWorkshops();
        })
        .catch(() => {
          serverBus.$emit('show-snackbar', false);
        })
    }
  },

  data: () => ({
    dialog: false,
    deleteDialog: false,
    dialogTitle: 'Dodaj radionicu',
    deleteText: 'Operacija koju ste zatražili može imati za posljedicu gubitak određenog dijela podataka. Jeste li sigurni da želite izbrisati odabrani resurs?',
    breadcrumbs:[
      {
        text: 'Radionice',
        disabled: true,
      }
    ],
    form: {},
    search: '',
    selectSearch: '',
    options: {
      sortBy: ['created_at'],
      sortDesc: [true]
    },
    totalItems: 0,
    workshops: [],
    workshopId: null,
    dropzones: true,
    fileId: null,
    file: {},
    dzSingle: {
      url: 'https://api.eduid.sum.ba/files',
      method: 'POST',
      maxFilesize: 20,
      maxFiles: 1,
      dictDefaultMessage: 'Priložite datoteku..',
      addRemoveLinks: true,
      withCredentials: true
    },
    items: [
      {
          data: "title",
          name: 'title',
          options: '',
          rules: 'required',
          tag: 'input',
          title: 'Naziv radionice',
          type: 'text'
      },
      {
          data: "subtitle",
          name: 'subtitle',
          options: '',
          rules: '',
          tag: 'input',
          title: 'Podnaslov',
          type: 'text'
      },
      {
          data: "contact_email",
          name: 'contact_email',
          options: '',
          rules: '',
          tag: 'input',
          title: 'Kontakt mail',
          type: 'text'
      },
      {
          data: "image_id",
          name: 'image_id',
          options: '',
          rules: '',
          tag: 'file',
          title: 'Odaberite sliku',
          type: 'number'
      },
      {
          data: "description",
          name: 'description',
          options: '',
          rules: '',
          tag: 'html',
          title: 'Opis radionice',
          type: 'text'
      },
      {
        data: "tags",
        name: 'tags',
        options: '',
        source: [],
        rules: '',
        tag: 'multiselect',
        title: 'Oznake',
        type: 'text'
      },
      {
          data: "location",
          name: 'location',
          options: '',
          rules: '',
          tag: 'input',
          title: 'Lokacija',
          type: 'text'
      },
      {
          data: "meet_url",
          name: 'meet_url',
          options: '',
          rules: '',
          tag: 'input',
          title: 'Link radionice',
          type: 'text'
      },
      {
          data: "date_from",
          name: 'date_from',
          options: '',
          rules: '',
          tag: 'date',
          title: 'Datum početka',
          type: 'text',
          model: false
      },
      {
          data: "date_to",
          name: 'date_to',
          options: '',
          rules: '',
          tag: 'date',
          title: 'Datum završetka',
          type: 'text',
          model: false
      },
      {
          data: "time_start",
          name: 'time_start',
          options: '',
          rules: '',
          tag: 'time',
          title: 'Vrijeme početka',
          type: 'text',
          model: false
      },
      {
          data: "time_end",
          name: 'time_end',
          options: '',
          rules: '',
          tag: 'time',
          title: 'Vrijeme završetka',
          type: 'text',
          model: false
      },
      {
          data: "register_until_date",
          name: 'register_until_date',
          options: '',
          rules: '',
          tag: 'date',
          title: 'Prijava do (datum)',
          type: 'date',
          model: false
      },
      {
          data: "register_until_time",
          name: 'register_until_time',
          options: '',
          rules: '',
          tag: 'time',
          title: 'Prijava do (vrijeme)',
          type: 'time',
          model: false
      }
    ]
  }),
};
</script>
