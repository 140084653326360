<template>
  <v-app>
    <!-- <v-app-bar elevation="0" color="primary" height="80px">
      <div class="d-flex justify-end" style="width: 100%">
        <v-btn
          depressed
          color="primary"
          class="text-none"
          large
          @click="login()"
        >
          Prijavi se
          <v-icon class="ml-2" color="white">mdi-import</v-icon>
        </v-btn>
      </div>
      <v-container>
        <v-row class="align-center justify-center justify-sm-space-between">
          <img style="width: 250px; max-height: 48px" src="../../assets/img/sumit_white.svg" />
          <span class="text-right white--text font-weight-light text-caption text-sm-body-1">{{todayDate}}</span>
        </v-row>
      </v-container>
    </v-app-bar> -->

    <v-container fluid>
      <v-row>
        <v-img max-height="100%" src="../../assets/img/prijava-vizual-1920.png" class="login-img"></v-img>
      </v-row>
    </v-container>

    <v-container class="d-flex pa-3 pa-sm-10 justify-center flex-column" fluid>
      <v-row justify="center">
        <h1>SUMIT Radionice</h1>
      </v-row>
      <div
        class="my-7"
        style="height: 1px; background: linear-gradient(90deg,#fff,#084776,#fff);"
      />
      <v-row class="mt-5">
        <v-col cols="12">
          <h3 class="title">Radionice u tijeku</h3>
        </v-col>
        <v-col cols="12">
          <swiper :options="swiperOptions" pagination navigation >
            <swiper-slide
              v-for="workshop in workshopsInProgress"
              :key="workshop.id"
            >
              <v-card
                class="pa-2"
                outlined
              >
                <v-list-item-title
                  class="text-body-1 pa-4 pb-0 font-weight-bold mb-1"
                >
                  {{ workshop.title }}
                </v-list-item-title>
                <v-card-text style="width: unset">
                  <div
                    class="d-flex justify-center blue-grey lighten-5 my-2"
                  >
                    <v-img
                      v-if="workshop.image"
                      eager
                      :src="`https://api.eduid.sum.ba/files/${workshop.image.uuid}`"
                    ></v-img>
                    <v-img
                      v-else
                      eager
                      src="../../assets/img/default-slika-za-tečajeve.jpg"
                    ></v-img>
                  </div>
                  <v-list-item-subtitle class="my-2" style="height: 20px;">
                    {{ workshop.subtitle }}
                  </v-list-item-subtitle>
                  <div>
                    <span class="font-weight-light">Datum početka:</span> {{ $parseDate(workshop.date_from) }}
                  </div>
                  <div>
                    <span class="font-weight-light">Vrijeme početka:</span> {{ $parseTime(workshop.time_start) }}
                  </div>
                </v-card-text>
                <v-card-actions class="px-3 pr-6">
                  <v-btn
                    class="text-none white--text"
                    depressed
                    block
                    color="primary"
                    @click="goToDetails(workshop.id)"
                  >
                    Prijavi se
                  </v-btn>
                </v-card-actions>
              </v-card>
            </swiper-slide>
          </swiper>
        </v-col>
      </v-row>
      <v-divider class="my-15"></v-divider>
      <v-row>
        <v-col cols="12">
          <h3 class="title">Završene radionice</h3>
        </v-col>
        <v-col cols="12">
          <swiper
            :options="swiperOptions"
            ref="mySwiper"
          >
            <swiper-slide
              v-for="workshop in completedWorkshops"
              :key="workshop.id"
            >
              <v-card
                class="pa-2"
                outlined
              >
                <v-list-item-title
                  class="text-body-1 pa-4 pb-0 font-weight-bold mb-1"
                >
                  {{ workshop.title }}
                </v-list-item-title>
                <v-card-text style="width: unset">
                  <div
                    class="d-flex justify-center blue-grey lighten-5 my-2"
                  >
                    <v-img
                      v-if="workshop.image"
                      eager
                      :src="`https://api.eduid.sum.ba/files/${workshop.image.uuid}`"
                    ></v-img>
                    <v-img
                      v-else
                      eager
                      src="../../assets/img/default-slika-za-tečajeve.jpg"
                    ></v-img>
                  </div>
                  <v-list-item-subtitle class="my-2" style="height: 20px;">
                    {{ workshop.subtitle }}
                  </v-list-item-subtitle>
                  <div>
                    <span class="font-weight-light">Radionica održana:</span> {{ $parseDate(workshop.date_from) }}
                  </div>
                </v-card-text>
                <v-card-actions class="px-3 pr-6">
                  <v-btn
                    class="text-none white--text"
                    depressed
                    block
                    color="teal lighten-2"
                    @click="goToDetails(workshop.id)"
                  >
                    Detalji
                  </v-btn>
                </v-card-actions>
              </v-card>
            </swiper-slide>
          </swiper>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="8">
          <h2 class="text-center headline">
            Dobrodošli na SUMIT sustav "prijava.sum.ba".
          </h2>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="8">
          Sustav je namijenjen za jednostavnu i laku online prijavu i upis korisnicima na:
          <ul>
            <li>tečajeve</li>
            <li>radionice</li>
            <li>edukacije</li>
            <li>webinare</li>
            <li>konferencije</li>
            <li>događaje itd.</li>
          </ul>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="8">
          Pomoću ove aplikacije omogućena je prijava svim učenicima i studentima, učiteljima i nastavnicima te nastavnom osoblju koji imaju izdan eduID (elektornički identitet) na edukacijske sadržaje.
        </v-col>
      </v-row>
      <v-row justify="center" class="mb-10">
        <v-col cols="12" sm="8">
          <v-card outlined style="cursor: pointer">
            <a href="https://eduid.ba" target="_blank" class="text-decoration-none">
              <v-row>
                <v-col cols="10">
                  <v-card-title>Ako nemate eduID</v-card-title>
                  <v-card-subtitle>
                    Kliknite i zatražite ga
                  </v-card-subtitle>
                </v-col>
                <v-col cols="2">
                  <div class="d-flex justify-center align-center" style="height: 100%">
                    <v-icon size="32">mdi-chevron-right</v-icon>
                  </div>
                </v-col>
              </v-row>
            </a>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row class="align-center justify-center justify-sm-space-between" style="color: rgba(0, 0, 0, 0.6);">
        <a href="https://sumit.sum.ba/" target="_blank">
          <v-hover>
            <v-img
              slot-scope="{ hover }"
              v-if="hover"
              src="../../assets/img/sumit-color.svg"
              class="sum-logo"
              width="305"
            >
            </v-img>
            <v-img
              v-else
              src="../../assets/img/sum-grey.svg"
              class="sum-logo"
              width="305"
            >
            </v-img>
          </v-hover>
        </a>
        <span class="copyright font-weight-light">SUMIT &copy; 2020</span>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import Api from "../../services/Api"

export default {
  components: {
  },
  data: () => ({
    workshops: [],
    swiperOptions: {
      slidesPerView: 1.2,
      mousewheel: {
        invert: true,
      },
      grabCursor: true,
      spaceBetween: 25,
      breakpoints: {
        640: {
          slidesPerView: 2.3,
          spaceBetween: 30
        },
        960: {
          slidesPerView: 3.3,
          spaceBetween: 40
        },
        1200: {
          slidesPerView: 4.3,
          spaceBetween: 40
        }
  }
    }
  }),

  created() {
    document.title = "SUMIT prijave"
    this.getWorkshops()
  },

  computed: {
    todayDate() {
      const monthNames = ["siječanj", "veljača", "ožujak", "travanj", "svibanj", "lipanj",
        "srpanj", "kolovoz", "rujan", "listopad", "studeni", "prosinac"];
      let dateObj = new Date();
      let month = monthNames[dateObj.getMonth()];
      let day = String(dateObj.getDate()).padStart(1, '0');
      let year = dateObj.getFullYear();
      let output = `${day}. ${month} ${year}. godine`

      return output
    },

    completedWorkshops () {
      return this.workshops.filter(workshop => workshop.is_finished)
    },

    workshopsInProgress () {
      return this.workshops.filter(workshop => !workshop.is_finished)
    },

    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },

  mounted() {
    this.swiper.slideTo(3, 1000, false)
  },

  methods :{
    login() {
      let apiUrl = process.env.VUE_APP_WORKSHOPS_API_URL;
      window.location.href = `${apiUrl}sso/login?redirect_to=${location.href}radionice`
    },

    goToDetails(id) {
      let apiUrl = process.env.VUE_APP_WORKSHOPS_API_URL;
      window.location.href = `${apiUrl}sso/login?redirect_to=${location.href}radionice/${id}`
    },

    getWorkshops () {
      Api.get('workshops', {
        params: {
          perPage: -1,
          sortBy: 'created_at',
          sortDesc: true,
          with: [
              'image'
          ]
        }
      }).then(response => {
          this.workshops = response.data.data;
        })
        .catch(error => {
          console.log(error);
        })
    }
  }
};
</script>
