import VuexStore from '../store';

import {serverBus} from '../main'

const Helper = {
    install(Vue) {

        Vue.mixin({
            /* mounted() {
                console.log('Mounted!');
            },*/
        });

        Vue.prototype.$getUser = function () {
            return VuexStore.state.user;
        };

        Vue.prototype.$getTable = function () {
            return VuexStore.state.table;
        };

        Vue.prototype.$getData = function () {
            return VuexStore.state.data;
        };

        Vue.prototype.$navigate = function (path) {
            let self = this;

            setTimeout(function () {
                self.$router.push(path);
            }, 20);
        };

        Vue.prototype.$useDialog = function () {
            return VuexStore.state.editInDialog;
        };

        Vue.prototype.$checkRole = function (role) {
            return VuexStore.state.roles.includes(role)
        }

        Vue.prototype.$checkPermission = function (permission) {
            if (permission === 'for everyone') {
                return true;
            }

            /* for (let i = 0; i < VuexStore.state.permissions.length; i++) {
                if (VuexStore.state.permissions[i] === permission) {
                    return true;
                }
            } */

            return false;
        };

        Vue.prototype.$checkAllPermissions = function (permissions) {

            for (let i = 0; i < permissions.length; i++) {
                let any = false;

                for (let j = 0; j < VuexStore.state.permissions.length; j++) {
                    if (permissions[i] === VuexStore.state.permissions[i]) {
                        any = true;
                        break;
                    }
                }

                if (any === false) {
                    return false;
                }
            }

            return true;
        };

        Vue.prototype.$checkAnyOfPermissions = function (permissions) {

            for (let i = 0; i < permissions.length; i++) {
                for (let j = 0; j < VuexStore.state.permissions.length; j++) {
                    if (permissions[i] === VuexStore.state.permissions[i]) {
                        return true;
                    }
                }
            }

            return true;
        };

        Vue.prototype.$parseDate = function (date) {
            if (!date) return null;

            const [newDate, time] = date.split(" ");


            const [year, month, day] = newDate.split('-');

            if(time) {
                const [hours, minutes] = time.split(":");

                return `${day}.${month}.${year} u ${hours}:${minutes}h`;
            } else {
                return `${day}.${month}.${year}`;
            }
        };

        Vue.prototype.$parseTime = function (time) {
            if (!time) return null;

            const [hours, minutes] = time.split(":");

            return `${hours}:${minutes}h`;
        };

        

        Vue.prototype.$destroyEvents = function () {
            let events = [
                'reload-table-data', 'filters-changed', 'perform-search',
            ];

            for (let i = 0; i < events.length; i++) {
                let event = events[i];
                serverBus.$off(event);
            }

            return false;
        };

    }
};

export default Helper;
